<template>
  <v-app id="root">
    <v-app-bar app dense dark color="primary" class="app-header">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="d-none d-md-flex">
        <v-row no-gutters>
          <v-col align-self="center"><v-img class="ma-0 pa-0" :src="icon" width="30" height="30" /></v-col>
          <v-col align-self="center" class="title ml-2">{{ name }}</v-col>
        </v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select
        v-if="companies.length"
        v-model="selectedCompany"
        :items="companies"
        item-value="id"
        item-text="nombre"
        dense
        solo
        prepend-inner-icon="mdi-domain"
        class="mx-5"
        light
        hide-details
        style="max-width: 300px"
      >
      </v-select>
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="text-normal mx-1 d-none d-sm-flex" v-bind="attrs" v-on="on" text>
            {{ username }}
            <v-icon right>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list color="secondary">
          <v-list-item>
            <v-btn block class="text-normal" @click.native="profile()">
              <v-list-item-title>Perfil</v-list-item-title>
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn block class="text-normal" @click.native="logout()">
              <v-list-item-title>Salir</v-list-item-title>
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer dark app color="secondary" v-model="drawer" class="app-sidemenu" temporary width="270">
      <v-list-item>
        <v-list-item-content class="text-center">
          <v-list-item-title class="title">
            <v-container>
              <v-img :src="logo" />
            </v-container>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <template v-for="item in items">
          <v-list-item :key="item.title" :to="item.path" link color="white">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <v-row align="center" justify="space-around" class="mb-2">
          <v-chip color="secondary" small> v{{ version }} </v-chip>
          <v-btn text small elevation="0" color="white" class="text-normal" :loading="loading" @click.native="logout()">
            Salir
            <v-icon right dark>mdi-logout</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view :company="selectedCompany"></router-view>
      <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
        {{ msgSuccess }}
      </v-snackbar>
      <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
        {{ msgError }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'root',
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    version: process.env.VUE_APP_VERSION,
    name: process.env.VUE_APP_NAME,
    logo: require('@/assets/logo_menu.png'),
    icon: require('@/assets/icon.png'),
    path: '/api/logout',
    username: '',
    rol: [],
    companies: [],
    selectedCompany: null,
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    drawer: false,
    items: [
      { icon: 'mdi-home', title: 'Inicio', path: '/home' },
      { icon: 'mdi-account-circle', title: 'Perfil', path: '/perfil' },
      { icon: 'mdi-traffic-light', title: 'Semáforo', path: '/semaforo' },
      {
        icon: 'mdi-card-account-details',
        title: 'Operadores',
        path: '/operadores'
      }
      // { icon: 'mdi-map-marker', title: 'Mapa', path: '/mapa' },
      // { icon: 'mdi-file-cog', title: 'Pruebas de simulación', path: '/pruebas-de-simulacion' },
    ]
  }),
  mounted() {
    this.check()
  },
  watch: {
    selectedCompany: function (oldVal, newVal) {
      if (newVal && oldVal != newVal) {
        this.setCompany({ id: this.selectedCompany })
      }
    }
  },
  methods: {
    ...mapActions('general', ['setCompany', 'clearData']),
    check() {
      this.username = this.$session.get('user')
      this.rol = this.$session.get('rol')
      this.companies = this.$session.get('companies')
      this.selectedCompany = this.companies.length ? this.companies[0].id : null
      this.setCompany({ id: this.selectedCompany })
    },
    profile() {
      if (this.$route.path !== '/perfil') {
        this.$router.push('/perfil')
      }
    },
    logout() {
      if (!this.$session.exists()) {
        this.$router.push('/login')
        return
      }
      this.loading = true
      this.axios
        .post(this.host + this.path)
        .then((response) => {
          this.clearData()
          this.$session.destroy()
          this.$router.push('/login')
        })
        .catch((error) => {
          this.error = true
          this.msgError = error.response.data.message
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.app-header {
  z-index: 99;
}
.app-sidemenu {
  z-index: 99;
}
.text-normal {
  text-transform: none !important;
}
</style>
